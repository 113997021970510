import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { camelCase, debounce, snakeCase } from 'lodash'
import mixins from '@/mixins'
import mainFilter from '../../components/filter/index.vue'
import reviewsDataTable from '../../components/reviews-data-table/index.vue'

export default {
  name: 'action-plans',
  mixin: [mixins],
  components: {
    reviewsDataTable,
    mainFilter
  },
  filters: {
    formattedDate (value) {
      if (!value) return ''
      return moment(value).format('DD.MM.YYYY')
    }
  },
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null,
      openDialogView: false,
      editedItem: null,
      filter: ''
    }
  },
  computed: {
    ...mapGetters({
      list: 'reviews/list',
      limit: 'reviews/limit',
      skip: 'reviews/skip',
      isListLoading: 'reviews/isListLoading',
      listLength: 'reviews/listLength',
      isLoading: 'reviews/isLoading',
      setting: 'courses/setting',
      simpleCoursesList: 'reviews/simpleList',
      filterCourseId: 'reviews/filterCourseId'
    }),
    getLocalStorageFilter () {
      return JSON.parse(localStorage.getItem('reviews_filters'))
    },
    getLocalStorageSearch () {
      return JSON.parse(localStorage.getItem('reviews_search'))
    },
    getLocalStorageFilterCourseId () {
      return localStorage.getItem('reviews_filter_course_id')
    }
  },
  created () {
    const courseId = +this.$route.params.courseId
    if (!!this.filterCourseId && this.filterCourseId !== courseId) {
      this.changeFilter()
    } else {
      this.getLocalStorageDataFilter()
    }

    if (!this.list.length) {
      this.fetchList(this.$route.params)
    }
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
    this.courseList(this.$route.params)
  },
  methods: {
    ...mapActions({
      fetchList: 'reviews/GET_LIST',
      remove: 'reviews/DELETE',
      reset: 'reviews/RESET',
      courseList: 'reviews/COURSE_LIST'
    }),
    ...mapMutations({
      setCurrentItem: 'reviews/SET_ITEM',
      setListContent: 'reviews/SET_LIST',
      setList: 'reviews/SET_LIST',
      changeFixBody: 'system/FIX_BODY',
      changeSkip: 'reviews/CHANGE_SKIP',
      changeSort: 'reviews/CHANGE_SORT',
      changeFilter: 'reviews/CHANGE_FILTER',
      setFilterData: 'reviews/setFilterData',
      setFilterCourseId: 'reviews/setFilterCourseId'
    }),
    customSort (items, nameField, isDesc) {
      const toSnakeIndex = snakeCase(nameField[0])
      let sortData = ''

      if (!nameField.length) {
        this.changeSort(sortData)
        this.changeSkip(0)

        // this.fetchList(this.$route.params)

        return items
      }

      if (nameField) {
        if (isDesc) {
          sortData = `&sorting[field]=${toSnakeIndex}&sorting[direction]=desc`
        } else {
          sortData = `&sorting[field]=${toSnakeIndex}&sorting[direction]=asc`
        }
      } else {
        sortData = ''
      }

      this.changeSort(sortData)
      this.changeSkip(0)

      return items
    },
    toCamelCase (str) {
      return camelCase(str)
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList(this.$route.params)
    },
    closeDialogView () {
      setTimeout(() => {
        this.openDialogView = false
      }, 500)
    },
    changeDialogView (item) {
      this.editedItem = item
      this.editedItem.workType = this.toCamelCase(item.workType)
      this.openDialogView = true
    },
    removeListItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },

    resetListItem () {
      this.reset(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success('Success')

        this.fetchList(this.$route.params)
      })
    },

    updateFilter (params) {
      this.filter = params
    },

    onChangeFilter () {
      this.page = 1
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      item.course_id = this.$route.params.course_id
      this.removingItem = item
    },
    sendRequest: debounce(function () {
      this.fetchList(this.$route.params)
    }, 1000),
    hasShowCancel (item) {
      // if (item.status.data.id === 8) return false;
      if (item.status.data.title === 'canceled' || item.status.data.title ===
        'to_retake') {
        return false
      }

      // let work = item.homeWork.data.id ?  item.homeWork : item.graduateWork;
      // if (!work.data.repeatedly) return false;

      return true
    },
    getLocalStorageDataFilter () {
      const courseId = +this.$route.params.courseId
      this.setFilterCourseId(+this.getLocalStorageFilterCourseId)
      if (!!this.filterCourseId && this.filterCourseId === courseId) {
        this.setFilterData(this.getLocalStorageFilter)
      } else {
        localStorage.removeItem('reviews_filters')
        localStorage.removeItem('reviews_filter_course_id')
      }
    }
  },
  destroyed () {
    this.setListContent([])
    this.setList([])
  }
}
