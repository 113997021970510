import moment from 'moment'

export default {
  name: 'ReviewsDataTable',
  inheritAttrs: false,
  filters: {
    formattedDate (value) {
      if (!value) return ''
      return moment(value).format('DD.MM.YYYY hh:mm')
    },
    commentPreviewText (value) {
      if (value.length < 50) return value
      return value.substr(0, 50)
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'Date',
          value: 'createdAt'
        },
        {
          text: 'Lesson / Homework',
          value: 'name'
        },
        {
          text: 'Module',
          value: 'moduleName'
        },
        {
          text: 'Student',
          value: 'studentFullName'
        },
        {
          text: 'E-mail',
          value: 'studentEmail'
        },
        {
          text: 'Rating',
          value: 'rating'
        },
        {
          text: 'Status',
          value: 'status',
          sort: (a, b) => {
            const nameA = a.data.title.toLowerCase()
            const nameB = b.data.title.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
        {
          text: 'Review',
          value: 'description'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  }
}
